import http from '../http'
import qs from 'qs'

export default {
  /**
   * 获取配置
   */
  wxConfig: (params) => http.post('/common/wx/config', params),
  /**
   * 授权
   */
  authorize: (params) => http.post(`/authorize?${qs.stringify(params)}`),
  /**
   * 获取用户信息
   */
  getUserOpenId: (params) => http.post('/user/openId', params),
  /**
   * 获取用户详细信息
   */
  getUserDetail: (params) => http.post('/user/detail', params),
  /**
   * 微信分享
   */
  wxShare: (params) =>
    http.get(`/tool/micro/common/vx/share?${qs.stringify(params)}`),
}
