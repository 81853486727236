import Vue from 'vue'
import Vuex from 'vuex'
import {
  isValExist
} from '@/util/tool'
Vue.use(Vuex)
const state = {
  vote_num: 0, //投票数量
  codeId: 0, //微信授权codeid
  openId: isValExist(localStorage.getItem('openId')) ? localStorage.getItem('openId') : '',
  WXUserInfo: localStorage.getItem('WXUserInfo') &&
    localStorage.getItem('WXUserInfo') !== 'undefiined' &&
    localStorage.getItem('WXUserInfo') !== 'null' ?
    localStorage.getItem('WXUserInfo') : '',
  userInfo: isValExist(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem(
    'userInfo')) : {},
}
const getters = {
  //实时监听state值的变化(最新状态)
  select_ticket(state) {
    return state.select_ticket
  },
}
const mutations = {
  set_vote_num(state, num) {
    console.log(num, '测试啊');
    state.vote_num = num
  },
  set_codeId(state, codeId) {
    window.localStorage.setItem('tempWXCode', codeId)
    state.codeId = codeId
  },
  set_openId(state, openId) {
    window.localStorage.setItem('openId', isValExist(openId) ? openId : '')
    state.openId = isValExist(openId) ? openId : ''
  },
  // 保存微信用户信息
  set_wx_uerinfo(state, info) {
    window.localStorage.setItem('WXUserInfo', JSON.stringify(info))
    state.WXUserInfo = JSON.stringify(info)
  },
  // 同步保存登录用户信息
  set_user_info(state, userInfo) {
    window.localStorage.setItem('userInfo', userInfo ? JSON.stringify(userInfo) : '')
    state.userInfo = userInfo || {}
  },
}
const actions = {
  setWeiXinUerinfo(context, info) {
    context.commit('set_wx_uerinfo', info)
  },
  // 异步保存登录用户信息
  setUserInfoAsync(context, userInfo) {
    context.commit('set_user_info', userInfo)
  },
}
const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
})
export default store
