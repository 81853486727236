import http from '../http'
import qs from 'qs'

export default {
  /**
   * 获取活动首页相关数据
   */
  getInvitationCard: (params) =>
    http.get(`/tool/h5/Invitation/card?${qs.stringify(params)}`, {
      isToken: false
    }),
}
