import http from '@/http'
import qs from 'qs'
export default {
  // 查询h5聚合页
  onePageInfo: (params) => http.get(`/tool/h5/one/page/${params}`),
  // 聚合页表单提交
  submitOnePageForm: (params) => http.post('/tool/h5/one/page/form', params),
  // 聚合页日程表搜索
  scheduleSearch: (params) => http.get(`/tool/h5/one/page/schedule/search?${qs.stringify(params)}`),
}

