export default [{
    path: '/pick', // 抽奖
    name: 'pick',
    component: () => import('@/views/pick/index.vue'),
    meta: {
      title: '投票评选',
      tokenName: 'token'
    }
  },
  {
    path: '/rank', // 抽奖
    name: 'rank',
    component: () => import('@/views/pick/rank.vue'),
    meta: {
      title: '投票评选',
      tokenName: 'token'
    }
  },
  {
    path: '/pick-detail', // 抽奖
    name: 'pick-detail',
    component: () => import('@/views/pick/pick-detail.vue'),
    meta: {
      title: '投票评选',
      tokenName: 'token'
    }
  },
  {
    path: '/work-detail', // 抽奖
    name: 'work-detail',
    component: () => import('@/views/pick/work-detail.vue'),
    meta: {
      title: '投票评选',
      tokenName: 'token'
    }
  },
  {
    path: '/share', // 抽奖
    name: 'share',
    component: () => import('@/views/pick/share.vue'),
    meta: {
      title: '投票评选',
      tokenName: 'token'
    }
  },
  {
    path: '/more-work', // 抽奖
    name: 'more-work',
    component: () => import('@/views/pick/more-work.vue'),
    meta: {
      title: '投票评选',
      tokenName: 'token'
    }
  },
  {
    path: '/agreement', // 抽奖
    name: 'agreement',
    component: () => import('@/views/pick/agreement.vue'),
    meta: {
      title: '投票评选',
      tokenName: 'token'
    }
  },
  {
    path: '/search-result', // 抽奖
    name: 'search-result',
    component: () => import('@/views/pick/search-result.vue'),
    meta: {
      title: '投票评选',
      tokenName: 'token'
    }
  },
  {
    path: '/trophy-help', // 抽奖
    name: 'trophy-help',
    component: () => import('@/views/pick/trophy-help.vue'),
    meta: {
      title: '投票评选',
      tokenName: 'token'
    }
  },
  {
    path: '/nomination', // 抽奖
    name: 'nomination',
    component: () => import('@/views/pick/nomination.vue'),
    meta: {
      title: '投票评选',
      tokenName: 'token'
    }
  },
  {
    path: '/sign-up', // 抽奖
    name: 'sign-up',
    component: () => import('@/views/pick/sign-up.vue'),
    meta: {
      title: '投票评选',
      tokenName: 'token'
    }
  },
  {
    path: '/expire', // 抽奖
    name: 'expire',
    component: () => import('@/views/pick/expire.vue'),
    meta: {
      title: '投票评选',
      tokenName: 'token'
    }
  },
]
