import Vue from 'vue'
import VueRouter from 'vue-router'
import tlhdAggregate from './tlhd-aggregate'
import tlhdLotter from './tlhd-lottery'
import tlhdVote from './tlhd-vote'
import tlhdPick from './tlhd-pick'
import tlhdInvitation from './tlhd-invitation'

Vue.use(VueRouter)

const routes = [ ...tlhdAggregate, ...tlhdLotter, ...tlhdVote, ...tlhdPick, ...tlhdInvitation]

const router = new VueRouter({
  mode: process.env.NODE_ENV === 'development' ? 'hash' : 'history',
  base: process.env.NODE_ENV === 'development' ? '/h5-test/' : '/',
  routes,
})

router.beforeEach((to, from, next) => {
  // document.title = to.meta.title || ''
  next() // 确保一定要调用 next()
})
export default router

