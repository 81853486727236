export default [
  {
    path: '/invitation', // 抽奖
    name: 'invitation',
    component: () => import('@/views/invitation/index.vue'),
    meta: {
      title: '邀请函',
      tokenName: 'invitationToken'
    },
  }
]
