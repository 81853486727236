import axios from 'axios'
import router from '../router'
import { Toast, Notify } from 'vant'

const handleError = (response) => {
  const err = (text) => {
    Notify({ type: 'danger', message: text ?? response.msg })
  }
  if (!response) {
    err('请求超时，服务器无响应！')
    return
  }

  switch (response.code) {
    case 404:
      err('服务器资源不存在')
      break
    case 401:
    case 403:
    case 10028:
    case 10033:
      // 后续看看是否要清除
      err('登录状态已过期，需要重新登录')
      localStorage.removeItem('token', '')
      break
    case 500:
      err('服务器内部错误')
      break
    default:
      err()
      break
  }
}

const createConfig = {
  isLoading: true, // 是否需要Loading
  isToken: true, // 是否需要token
}

// 创建axios实例
const instance = axios.create({
  baseURL: process.env.VUE_APP_HOST,
  headers: { 'Content-Type': 'application/json;charset=UTF-8' },
})
// 添加请求拦截器
instance.interceptors.request.use(
  (instanceConfig) => {
    let config = {
      ...createConfig,
      ...instanceConfig,
    }
    let tokenName = router.history.current.meta.tokenName || 'token'
    // 每次请求都带上本地的token
    if (localStorage.getItem(tokenName) && config.isToken) {
      config.headers[tokenName] = localStorage.getItem(tokenName)
    }
    config.isLoading !== false && Toast.loading({ message: '加载中...', forbidClick: true, duration: 0 })
    return config
  },
  (err) => {
    Toast.clear()
    return Promise.reject(err)
  }
)

// 添加响应拦截器
instance.interceptors.response.use(
  (res) => {
    let { config, data } = res
    // token 失效问题，只能在对应的模块单独处理
    config.isLoading !== false && Toast.clear()
    // 判断错误
    const _code = [10036, 40063]
    if (data.code && !_code.includes(data.code)) {
      handleError(data)
    }
    return data
  },
  (err) => {
    Toast.clear()
    return Promise.reject(err)
  }
)
// 导出axios实例
export default instance
