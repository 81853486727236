import axios from 'axios'
import { Toast } from 'vant';

export function testPhone(phone) {
  let reg = /^1[3456789]\d{9}$/
  return reg.test(phone)
}
export function compatibleTime(timeVal) {
  return timeVal ? timeVal.replace(/-/g, '/') : timeVal
}
// 显示loading
let toastInstance;
export function showLoading(options) {
  toastInstance = Toast.loading(options ? options : {
    duration: 0, // 持续展示 toast
    forbidClick: true,
    message: '加载中...',
  });
}
// 隐藏loading
export function hideLoading() {
  if (toastInstance) toastInstance.clear()
}

// 判断是否是ios
export function isIOS() {
  const userAgent = navigator.userAgent
  const isiOS = userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  return !!isiOS
}
// 清空所有空格
export function clearEmpty(str) {
  return str ? str.replace(/\s+/g, '') : ''
}

// 判断值是否存在
export function isValExist(val) {
  return val !== undefined && val !== null && val !== 'undefined' && val !== 'null' && clearEmpty(val)
}

// 禁止页面回退，解决ios授权之后，再点击回退报错
export function forbidBack() {
  history.pushState(null, null, document.URL)
  window.addEventListener('popstate', function () {
    history.pushState(null, null, document.URL)
  })
}

/**
 * @method 格式化时间
 * @param time
 * @returns {string}
 */
export function getFormatTime(time) {
  const dateO = time ? new Date(time) : new Date()
  let year = dateO.getFullYear() //获取完整的年份(4位,1970-????)
  let month = dateO.getMonth() + 1 // 获取当前月份(0-11,0代表1月)
  let day = dateO.getDate() // 获取当前日(1-31)
  let hours = dateO.getHours() //获取当前小时数(0-23)
  let minutes = dateO.getMinutes() //获取当前分钟数(0-59)
  let seconds = dateO.getSeconds() //获取当前秒数(0-59)
  month < 10 && (month = `0${month}`)
  day < 10 && (day = `0${day}`)
  hours < 10 && (hours = `0${hours}`)
  minutes < 10 && (minutes = `0${minutes}`)
  seconds < 10 && (seconds = `0${seconds}`)
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

/**
 * @method 获取时间戳
 * @param time
 * @returns {string}
 */
export function getTimeStamp(time) {
  const timeType = getTargetType(time)
  if (timeType === 'String') {
    time = time.replace(/-/g, '/')
  }
  return new Date(time).getTime()
}

/**
 * @author liangcaixin
 * @method getTimeDiff 获取两个时间的差值
 * @param {String} firstDate 第一个时间
 * @param {String} secondDate 第二个时间
 * @example getTimeDiff('2021-06-03 15:06:30', '2021-06-13 15:06:30')
 *
 */
export function getTimeDiff(firstDate, secondDate, unit = 'full') {
  // 1.获取两个时间的时间戳
  const firstTime = new Date(firstDate).getTime()
  const secondTime = new Date(secondDate).getTime()
  // 2.获取时间间隔
  const timeSpan = secondTime - firstTime
  // 返回相差时间戳
  if (unit === 'stamp') return timeSpan
  // 3.获取天
  const day = Math.floor(timeSpan / 1000 / 60 / 60 / 24)
  // 返回相差天数
  if (unit === 'day') return day
  // 4.获取小时
  const hour = Math.floor(timeSpan / 1000 / 60 / 60)
  // 返回相差小时数
  if (unit === 'hour') return hour
  // 5.获取分钟
  const minute = Math.floor(timeSpan / 1000 / 60 - hour * 60)
  // 返回相差分钟数
  if (unit === 'minute') return minute
  // 6.获取秒
  const second = timeSpan / 1000 - hour * 60 * 60 - minute * 60
  // 返回相差秒数
  if (unit === 'second') return second
  // 返回相差时间的完整格式
  if (unit === 'full')
    return {
      day,
      hour,
      minute,
      second,
    }
}

/*
 * @method getTargetType 获取目标类型
 * @example getTargetType('中国人牛逼')
 */
/**
 * @method 获取目标类型
 * @param target
 * @returns {string}
 */
export function getTargetType(target) {
  let typeStr = Object.prototype.toString.call(target)
  // 都是返回这样的类型，就直接截取 [] 内的东西，再拿出里面的类型，然后直接返回对应的类型Array，Boolean
  return typeStr.slice(1, typeStr.indexOf(']')).split(' ')[1]
}

/**
 * @method 是否包含中文
 * @param val
 * @returns {boolean}
 */
export function isContainZh(val) {
  if (getTargetType(val) === 'String') {
    if (!clearEmpty(val)) return false
    const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g')
    return reg.test(val)
  } else {
    return false
  }
}

/**
 * @author liangcaixin
 * @method testEmail 校验邮箱是否合法
 * @param {String} url 需要校验的邮箱
 * @example testEmail('34134234234@qq.com')
 */
export function testEmail(email) {
  let reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
  return reg.test(email)
}

/**
 * @author liangcaixin
 * @method testUrl 校验链接是否合法
 * @param {String} url 需要校验的链接
 * @example testUrl('https://www.baidu.com/')
 */
export function testUrl(URL) {
  const str = URL,
    Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/,
    objExp = new RegExp(Expression)
  if (objExp.test(str) === true) {
    return true
  } else {
    return false
  }
}

/**
 * @author liangcaixin
 * @method getRequest 获取url参数
 * @param {String} url url, 不传就取当前url
 * @example getRequest()
 */
export function getRequest(url = window.location.href) {
  let theRequest = new Object()
  if (url.indexOf('?') !== -1) {
    let str = url.split('?')[1]
    let strArr = str.split('&')
    for (let i = 0; i < strArr.length; i++) {
      theRequest[strArr[i].split('=')[0]] = unescape(strArr[i].split('=')[1])
    }
  }
  return theRequest
}

/**
 * @author liangcaixin
 * @method createTempForm 创建一个临时表单
 * @param {String} url 不传就取当前url
 * @param {Object} params 请求参数
 * @example createTempForm()
 */
export function createTempForm(url, params) {
  // 创建form元素
  const temp_form = document.createElement('form')
  // 设置form属性
  temp_form.action = url
  temp_form.target = '_self'
  temp_form.method = 'post'
  temp_form.style.display = 'none'
  // 处理需要传递的参数
  for (let x in params) {
    let opt = document.createElement('textarea')
    opt.name = x
    opt.value = params[x]
    temp_form.appendChild(opt)
  }
  document.body.appendChild(temp_form)
  // 提交表单
  temp_form.submit()
}

/**
 * @author liangcaixin
 * @method downloadImg 下载图片
 * @param {String Or HTMLImageElement} imgTarget 要下载的图片dom节点，或者直接传图片链接，必传
 * @param {String} imgName 下载下来图片名字，如果不传就是'下载图片'，非必传
 * @param {Function} callback 下载完成后的回调函数，返回一个包含下载链接 + 图片名称的对象，非必传
 * @example 1，downloadImg(imgTarget, '二维码')
 * 最简单的调用方式
 * @example 2，downloadImg(imgTarget, '二维码', res => {})
 * 会在回调函数返回一个对象，包含图片链接，图片名字
 * @example 3，downloadImg(imgTarget, '二维码').then(res=>{})
 * 如果callback不传，就返回一个Promise对象，然后在then里可以拿到返回对象
 */
export function downloadImg(imgTarget, imgName = '下载图片', callback = null) {
  if (!imgTarget) return
  let downloadUrl = '' // 下载的图片链接
  let alink = document.createElement('a')
  alink.style = 'display: none;'
  document.documentElement.appendChild(alink)
  alink.setAttribute('download', imgName)
  // 如果传进来的是dom节点，就拿到dom节点的src属性，然后赋值给downloadUrl，
  // 如果直接是图片链接，就需要先将图片链接转成base64格式，不然无法自定义图片名字
  return new Promise((resolve) => {
    if (getTargetType(imgTarget) === 'HTMLImageElement') {
      downloadUrl = imgTarget.src
      alink.setAttribute('href', downloadUrl)
      alink.click()
      document.documentElement.removeChild(alink)
      const returnObj = {
        url: downloadUrl,
        name: imgName,
      }
      callback ? callback(returnObj) : resolve(returnObj)
    } else if (imgTarget.indexOf('data:image') !== -1) {
      downloadUrl = imgTarget
      alink.setAttribute('href', downloadUrl)
      alink.click()
      document.documentElement.removeChild(alink)
      const returnObj = {
        url: downloadUrl,
        name: imgName,
      }
      callback ? callback(returnObj) : resolve(returnObj)
    } else if (imgTarget.indexOf('https://tuoluohuodong') !== -1) {
      axios
        .post(`${process.env.VUE_APP_HOST}/file/common/file/to/base64`, {
          fileUrl: imgTarget,
        })
        .then((res) => {
          let { code, data } = res.data
          // 拼接成base64 图片地址链接下载
          if (code === 0) {
            alink.setAttribute('href', `data:image/png;base64,${data}`)
            alink.click()
            document.documentElement.removeChild(alink)
            const returnObj = {
              url: downloadUrl,
              name: imgName,
            }
            callback ? callback(returnObj) : resolve(returnObj)
          }
        })
    }
  })
}

/**
 * @author liangcaixin
 * @method scrollToSpecifiedLocation 页面元素滚动到指定位置
 * @param {Object} config 滚动参数
 * scrollObj 要滚动的dom元素
 * left 等同于  x-coord
 * top 等同于  y-coord
 * behavior  类型String,表示滚动行为,支持参数 smooth(平滑滚动),instant(瞬间滚动),
 *            默认值auto,实测效果等同于instant
 * location 可选值，top 滚动到顶部，bottom，滚动到底部
 */
export function scrollToSpecifiedLocation(config = {}) {
  const { scrollObj, top = 0, left = 0, behavior = 'smooth', location } = config
  const tempScrollObj = scrollObj || window
  let temp = 0
  top && (temp = top)
  ;(location === 'top' || top === 0) && (temp = 0)
  location === 'bottom' && (temp = tempScrollObj.scrollHeight - tempScrollObj.offsetHeight)
  tempScrollObj.scrollTo({
    top: temp,
    left,
    behavior,
  })
}

/**
 * 对缓存设置过期时间
 * @param {string} key 存储键
 * @param {any} data 存储值 (任意类型)
 * @param {number} expireTime 失效时间 默认1天（86400秒）
 * 使用例子 localStorage.setExpire('xxx', 123)
 */
Storage.prototype.setExpire = (key, data, expireTime = 86400) => {
  let obj = {
    data,
    expireTime,
    currentTime: Date.parse(new Date()) / 1000,
  }
  // 转json字符串
  localStorage.setItem(key, JSON.stringify(obj))
}

/**
 * 获取缓存数据
 * @param {string} key 存储时的键
 * @returns {string}
 * 使用例子 localStorage.getExpire('xxx')
 */
Storage.prototype.getExpire = (key) => {
  const val = localStorage.getItem(key)
  if (!val) return val
  const { currentTime, expireTime, data } = JSON.parse(val)
  if (Date.parse(new Date()) / 1000 - currentTime > expireTime) {
    localStorage.removeItem(key)
    return null
  }
  return data
}

/**
 * commonPay 公共支付
 * @param params
 *  payWay 支付方式
 *  paySuccessCallback 支付成功的回调
 *  payFailCallback 支付失败的回调
 *  payOrderParams 支付参数
 *  wxH5Callback 微信h5的回调
 *  advancePayCallback 预支付回调
 * @returns {Promise<never>}
 */
export const commonPay = async (params) => {
  let { payWay = 'wechatPay', paySuccessCallback, payFailCallback, payOrderParams, wxH5Callback, advancePayCallback } = params
  const ua = navigator.userAgent.toLowerCase()

  const onBridgeReady = (params) => {
    const { appId, timeStamp, nonceStr, packageStr, signType, paySign } = params
    window.WeixinJSBridge.invoke(
      'getBrandWCPayRequest',
      {
        appId, //公众号名称，由商户传入
        timeStamp, //时间戳，自1970年以来的秒数
        nonceStr, //随机串
        package: packageStr,
        signType, //微信签名方式：
        paySign, //微信签名
      },
      (res) => {
        if (res.err_msg == 'get_brand_wcpay_request:ok') {
          if (paySuccessCallback) {
            paySuccessCallback()
          }
        } else {
          if (payFailCallback) {
            payFailCallback()
          }
        }
      }
    )
  }
  // 微信支付
  if (payWay === 'wechatPay') {
    // 是否是纯正的微信环境（不包括企业微信）
    let isWeiXinTrue = ua.match(/MicroMessenger/i) == 'micromessenger' && !store.state.isWxWork
    let tradeTypeEnum = isWeiXinTrue ? 'JSAPI' : 'MWEB'
    payOrderParams.tradeTypeEnum = tradeTypeEnum
    if (tradeTypeEnum === 'JSAPI') {
      payOrderParams.openId = store.state.openId
      payOrderParams.source = 1
    }
    const { code, data, msg } = await orderApi.payOrder(payOrderParams)
    if (code === 0) {
      if (isWeiXinTrue) {
        onBridgeReady(data.jsParamVO)
      } else {
        if (wxH5Callback) {
          wxH5Callback(data.mwebUrl)
        } else {
          window.location.href = data.mwebUrl
        }
      }
    } else {
      return Promise.reject(msg)
    }
  } else if (payWay === 'aliPay') {
    payOrderParams.tradeTypeEnum = 'ALI_PAY_WAP'
    const { code, data, msg } = await orderApi.aliPay(payOrderParams)
    if (code === 0) {
      let htmls = data.formStr //后台返回的from表单
      const div = document.createElement('div')
      div.innerHTML = htmls
      document.body.appendChild(div)
      document.forms[0].acceptCharset = 'utf-8'
      document.forms[0].submit()
      if (advancePayCallback) {
        advancePayCallback()
      }
    } else {
      return Promise.reject(msg)
    }
  }
}

// 校验图片是否加载完毕
export const imgLoad = (img, callback) => {
  let timer = setInterval(() => {
    if (img.complete) {
      callback(img)
      clearInterval(timer)
    }
  }, 50)
}

let watermarkElement = null
let watermarkCheckTimer = null
/**
 * @author Alen
 * @method waterMark 创建文字水印
 * @param {string} text 需要展示的文字水印
 * @returns {Object} {add: 添加水印 clear: 清除水印}
 * @example waterMark().add()
 */
export const waterMark = (text = '免费测试版') => {
  const add = () => {
    if (watermarkElement) reurn // 如果水印已存在，不重复添加

    watermarkElement = document.createElement('div')
    watermarkElement.id = 'watermark'
    watermarkElement.className = 'watermark'
    watermarkElement.dataset.watermark = 'true'

    const watermarkText = document.createElement('span')
    watermarkText.className = 'watermark-text'
    watermarkText.textContent = text
    watermarkElement.appendChild(watermarkText)

    document.body.appendChild(watermarkElement)

    start()
  }

  const start = () => {
    if (!watermarkCheckTimer) {
      watermarkCheckTimer = setInterval(() => {
        if (!document.getElementById('watermark')) {
          watermarkElement = null // 将全局变量重置为null，以便允许重新添加水印
          add()
        }
      }, 1000)
    }
  }

  const clear = () => {
    if (watermarkElement) {
      document.body.removeChild(watermarkElement)
      watermarkElement = null

      if (watermarkCheckTimer) {
        clearInterval(watermarkCheckTimer)
        watermarkCheckTimer = null
      }
    }
  }

  return { add, clear }
}

/**
 * @author Alen
 * @method getProjConfigInfo 获取项目的配置信息
 * @param {string} projectId 当前项目的ID
 * @returns Object 返回对应工具的到期时间/去版权/状态等配置信息
 */
export const getProjConfigInfo = (projectId) => {
  showLoading()
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `${process.env.VUE_APP_HOST}/tool/h5/tool/user/project/${projectId}`,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    }).then((res) => {
      hideLoading()
      const { code, msg } = res.data
      if (code) {
        Toast(msg);
        reject(msg)
        return
      }
      resolve(res.data)
    })
  })
}
