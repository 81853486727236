import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import api from '@/api/index'
import axios from 'axios'

import vant from 'vant'
Vue.use(vant)

import 'vant/lib/index.css'
import './assets/flex.scss'
import './assets/common.scss'
import './assets/pickStyle.scss'
const OSSHOST = 'https://eventimg.oss-cn-shenzhen.aliyuncs.com'
Vue.prototype.$api = api
Vue.prototype.$axios = axios
Vue.prototype.TOOL_IMG_HOST = `${OSSHOST}/active_tool/h5`
Vue.prototype.H5_IMG_HOST = `${OSSHOST}/activity_h5`

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  to.meta.title && (document.title = to.meta.title)
  next()
})

Vue.config.productionTip = process.env.NODE_ENV === 'development'

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

