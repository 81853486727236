export default [
  {
    path: '/aggregate',
    name: 'aggregate',
    component: () => import('@/views/aggregate/index.vue'),
    meta: {
      title: '聚合页',
    },
  },
]
