<template>
  <div id="app">
    <keep-alive>
      <routerView v-if="$route.meta.keepAlive"></routerView>
    </keep-alive>
    <routerView v-if="!$route.meta.keepAlive"></routerView>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {},
}
</script>
<style lang="scss"></style>

