export default [
  {
    path: '/lottery', // 抽奖
    name: 'lottery',
    component: () => import('@/views/lottery/index.vue'),
    meta: {
      title: '抽奖',
    },
  },
  {
    path: '/lottery-record', // 中奖纪录
    name: 'lottery-record',
    component: () => import('@/views/lottery/lottery-record.vue'),
    meta: {
      title: '中奖纪录',
    },
  },
]
