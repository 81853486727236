import http from '../http'
import qs from 'qs'
export default {
  // 获取首页数据
  getActivityIndex: () => http.get(
    `/tool/h5/pick/activity/index/${localStorage.getItem('activityId')}`),
  // 获取banner图
  getActivityBanner: params => http.get(`/tool/h5/pick/activity/banner?${qs.stringify(params)}`),
  // 热门项目
  getHotProject: params => http.get(`/tool/h5/pick/activity/work/list?${qs.stringify(params)}`),
  // 最新参评项目
  getNewProject: params => http.get(`/tool/h5/pick/activity/new?${qs.stringify(params)}`),
  // 获取奖项类别
  getPrizeTypeList: params => http.get(`/tool/h5/prizes/${params}`),
  // 获取奖项类别下面的所有奖项
  getPrizeList: params => http.get(`/tool/h5/prize/detail/${params}`),
  // 参评报名
  signUp: params => http.post('/tool/h5/pick/works', params),
  // 入围提名
  getShortList: params => http.get(`/h5/pick/activity/short/list?${qs.stringify(params)}`),
  // 评选详情
  getPickDetail: params => http.get(`/tool/h5/pick/activity/${params}`),
  // 作品详情
  getWorkDetail: params => http.get(`/tool/h5/works/${params}`),
  // 奖杯赠送排行
  getGiveRank: params => http.get(`/h5/works/good/order?${qs.stringify(params)}`),
  // 投票，每天一次
  vote: params => http.post(`/tool/h5/works/good/${params}`),
  // 排行榜
  getTopList: params => http.get(`/tool/h5/pick/activity/top?${qs.stringify(params)}`),
  // 搜索
  handleSearch: params => http.get(`/tool/h5/pick/activity/search?${qs.stringify(params)}`),
  // 获取用户openId
  getOpenId: params => http.post('/user/openId', params),
  // 提交订单
  submitOrder: params => http.post('/h5/works/good/order', params),
  // 获取分享信息
  getShareInfo: params => http.get(`/tool/h5/pick/activity/share/${params}`),
  // 获取报名所需参数
  getAttendInfo: params => http.get(`/tool/h5/pick/activity/attend/info/${params}`),
  // 分享作品所需参数
  getShareAttend: params => http.get(`/tool/h5/work/share/${params}`),
  // 获取支付状态
  getPayStatus: params => http.post('/h5/works/good/order/status', params),
  // 浏览完成fbecland任务
  finishTask5: params => http.post(`/add/fbec/task/bak/${params}`),
}
