import http from '../http'
export default {
  /**
   * 上传文件
   */
  uploadFile: ({ file, name }) => {
    let formData = new FormData()
    formData.append('file', file)
    formData.append('name', name)
    formData.append('type', 'H5_REGISTRATION_SYSTEM')
    return http.post('/file/uploadFile', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  /**
   * 获取验证码
   */
  getVerificationCode: (params) => http.post('/sms/getVerificationCode', params),
  /**
   * 登录
   */
  login: (params) => http.post('/user/login', params),
  /**
   * 用户阅读协议
   */
  userRead: (params) => http.post('/user/read', params),
  /**
   * 是否申请注销账号
   */
  checkLoginOut: () => http.get('/user/logout/check?type=1'),
  /**
   * 注销账号
   */
  userLogout: () => http.post('/user/logout', { type: 1 }),
  /**
   * 注销账号
   */
  userLogoutCancel: () => http.post('/user/logout/cancel', { type: 1 }),
  /**
   * 获取登录用户信息
   */
  getUserToken: () => http.get('/user/getUserByToken'),
}
