import AGGREGATEPAGEAPI from './aggregatePageApi'
import LOTTERYAPI from './lotteryApi'
import ORDERAPI from './orderApi'
import USERAPI from './userApi'
import WXAPI from './wechatApi'
import PICKAPI from './pickApi'
import VOTEAPI from './voteApi'
import INVITATIONAPI from './invitationApi'
export default {
  AGGREGATEPAGEAPI,
  LOTTERYAPI,
  ORDERAPI,
  USERAPI,
  WXAPI,
  PICKAPI,
  VOTEAPI,
  INVITATIONAPI
}
