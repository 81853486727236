import http from '../http'
export default {
  /**
   * 获取活动首页相关数据
   */
  getInteractivePageData: (params) =>
    http.get(`/tool/h5/interactive/index/${params}`),
  /**
   * 互动活动用户-登录
   */
  interactiveLogin: (params) => http.post('/tool/h5/interactive/user/login', params),
  /**
   * 互动活动用户-注册
   */
  interactiveSignUp: (params) => http.post('/tool/h5/interactive/user', params),
  /**
   * 互动活动用户-参与抽奖
   */
  interactiveJoinIn: (params) => http.post('/tool/h5/interactive/user/attend', params),
  /**
   * 互动活动用户-中奖纪录
   */
  interactiveRecord: (params) => http.get(`/tool/h5/user/lotterys?interactiveId=${params}`),
}
