import http from '../http'
import qs from 'qs'
export default {
  /**
   * 获取投票作品内容
   */
  getRoundPlayer: (params) =>
    http.get(`/tool/h5/vote/round/player/${params}`),
  /**
   * 获取轮次基础信息及样式信息
   */
  getRound: (params) =>
    http.get(`/tool/h5/vote/round/${params}`),
  /**
   * 查询该轮次样式信息
   */
  getRoundStyle: (params) =>
    http.get(`/tool/h5/vote/round/style/${params}`),
  /**
   * 获取用户总剩余投票数
   */
  getVoteNum: (params) => http.get(`/tool/h5/vote/user/vote/num?${qs.stringify(params)}`),
  /**
   * 获取用户总剩余投票数
   */
  getPlayerRanks: (params) => http.get(`/tool/h5/vote/player/ranks?${qs.stringify(params)}`),
  /**
   * 获取用户信息
   */
  userDetail: (params) => http.post('/user/detail/', params),
  /**
   * 投票
   */
  userVote: (params) => http.post('/tool/h5/user/vote', params),
  /**
   * 获取详情
   */
  getRoundPlayerDetail: (params) =>
    http.get(`/tool/h5/vote/player/detail/${params}`),

}
