export default [
  {
    path: '/vote', // 抽奖
    name: 'vote',
    component: () => import('@/views/vote/index.vue'),
    meta: {
      title: '投票评选',
      tokenName: 'voteToken'
    }
  },
]
